import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(private authService: AuthenticationService, private router: Router) { }


  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        // console.log('isAuthenticated auto-login guard: ', isAuthenticated);
        if (isAuthenticated) {
          // Directly open inside area       
          // console.log('isAuthenticated auto-login guard is true***');
          
          this.router.navigateByUrl('/tabs', { replaceUrl: true });
        } else {          
          // Simply allow access to the login
          // console.log('isAuthenticated auto-login guard is false!!!');
          
          return true;
        }
      })
    )
  }
}

//   export class AutoLoginGuard implements CanActivate {

//     constructor(
//       public authService: AuthenticationService,
//       public router: Router
//     ){ }
  
//     canActivate(
//       next: ActivatedRouteSnapshot,
//       state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//       if(this.authService.isLoggedIn !== true) {
//         console.log('guard isLoggedIn');
//         this.router.navigate(['tabs'])
//       }
//       return true;
//     }
// }
