import { Injectable, NgZone } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { User } from "../model/aio.model";
import { AlertController, LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = "";
  userData: any;
  ngZone: NgZone;
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        sessionStorage.setItem("user", JSON.stringify(this.userData));
        this.isAuthenticated.next(true);
        JSON.parse(sessionStorage.getItem("user")!);
      } else {
        sessionStorage.setItem("user", "null");
        this.isAuthenticated.next(false);
        JSON.parse(sessionStorage.getItem("user")!);
      }
    });
  }

  signIn(email, password) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        // console.log("sign in result: ", result);

        this.SetUserData(result.user as User);
        this.afAuth.authState.subscribe((user) => {
          // console.log("user form authService: ", user);
          if (user) {
            const cUser: User = user;
            console.log("User: ", cUser);

            this.router
              .navigateByUrl("login", { skipLocationChange: true })
              .then(() => this.router.navigateByUrl('tabs'));
            // console.log("changing isAuthenticated to true...");

            return this.isAuthenticated.next(true);
          }
        });
      })
      .catch(async (error) => {
        const alert = await this.alertCtrl.create({
          header: "Connot Sign In",
          message: "Wrong username or password! Contact administrator.",
          buttons: ["Dismiss"],
        });
        await alert.present();
      });
  }

  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  async logout(): Promise<void> {
    this.loadingCtrl.create({
      message: 'Please wait...'
    }).then(async loadingEl => {
      loadingEl.present();
      this.isAuthenticated.next(false);
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      const currentUrl = this.router.url + "?";
      await this.router.navigateByUrl(currentUrl);
      this.router.navigated = false;
      this.router.navigateByUrl("login");
      return this.afAuth.signOut().then(() => {
        sessionStorage.removeItem("user");
        loadingEl.dismiss();
      });
    })
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem("user") as string);
    // console.log("session user: ", user);

    return user !== null ? true : false;
  }
}
